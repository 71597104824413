import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Result.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/states";
import Btn from '../Btn';
import Hint from '../Hint';
import IMask from 'imask';
const Result = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { page, isMobile, weight, fullWeight, dataResults, updatePolicy } = state;
  const [idResult, setIdResult] = useState(-1);
  const [isSubmitForm, setSubmitForm] = useState(false);
  const [isSubmitReaction, setSubmitReaction] = useState(false);
  const refWrap = useRef(null);
  const refInputForm = useRef(null);
  const refForm = useRef(null);
  const refReactionItems = useRef([]);

  useEffect(() => {
    const input = refInputForm.current;
    const maskPattern = '{@}*************************************************************************';
    const mask = IMask(input, {
      mask: maskPattern
    });
    input.addEventListener('focus', function () {
      if (mask.value.length > 0) return;
      mask.value = '@'
      mask.updateOptions({ mask: maskPattern });
    });

    input.addEventListener('blur', function () {
      if (input.value.trim() === '@') {
        mask.updateOptions({ mask: '' });
      }
    });
    const randomInteger = (min, max) => {
      // случайное число от min до (max+1)
      let rand = min + Math.random() * (max + 1 - min);
      return Math.floor(rand);
    }
    setIdResult(randomInteger(0, 4));
    document.querySelector('html').classList.add('autoheight');
    return () => {
      document.querySelector('html').classList.remove('autoheight');
    }
  }, []);
  useEffect(() => {
    if (idResult === -1) return;
    refWrap.current.style.opacity = 1;
  }, [idResult]);
  const submitForm = (e) => {
    e.preventDefault();
    if (isSubmitForm) return;
    setSubmitForm(true);
    refForm.current.classList.add(styles.submitted)
    let formData = new FormData();
    formData.append('name', refInputForm.current.value);
    const scriptURL = 'https://script.google.com/macros/s/AKfycbwZ8EHfoQ4lwH5chxC-Vd5eWbH4AiQwzB0F6b-QW2PlXSq2xn7GHUY6vUqLsaDVw74ASQ/exec';
    e.target.style.pointerEvents = 'none';
    fetch(scriptURL, {
      method: "POST",
      body: formData
    }).then(() => {
      // e.target.reset();

    }).catch((error) => console.error('Error!', error.message));
    setTimeout(() => {
      window.scrollTo({
        top: document.body.offsetHeight - window.innerHeight,
        behavior: "smooth",
      });
    }, 800);
  };
  const submitReaction = (index, i) => {
    if (isSubmitReaction) return;

    refReactionItems.current[i].classList.add(styles.active);
    refReactionItems.current.forEach(element => {
      element.style.pointerEvents = 'none';
    });
    setSubmitReaction(true);
    let formData = new FormData();
    formData.append('range', `${index}2`);
    const scriptURL = 'https://script.google.com/macros/s/AKfycbxSxmeWh6VKJkvmvhlHnIMmR3KpKt-AQpjck5bI9oI1yJuwcIxP573r3QHytWD3oE32XA/exec';

    fetch(scriptURL, {
      method: "POST",
      body: formData
    }).catch((error) => console.error('Error!', error.message));

    setTimeout(() => {

    }, 1000);
  };
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.inner}>
          <div className={styles.cover}>
            <div className={styles.cover__title}>
              You were the <br />
              {dataResults.length > 0 && dataResults[idResult] !== undefined && dataResults[idResult].title !== undefined ?
                ` ${dataResults[idResult].title}`
                :
                null
              }
              <div className={`${styles.cover__icon} ${styles.cover__icon_0}`}>
                <img src={`${path}/img/result-icon-0.svg`} alt="" />
              </div>
              <div className={`${styles.cover__icon} ${styles.cover__icon_1}`}>
                <img src={`${path}/img/result-icon-1.svg`} alt="" />
              </div>
            </div>
            <div className={styles.cover__img}>
              {dataResults.length > 0 && dataResults[idResult] !== undefined && dataResults[idResult].img !== undefined ?
                <picture>
                  <source media="(max-width: 768px)" srcSet={`${path}/img/${dataResults[idResult].img}-mobile.webp`} />
                  <img src={`${path}/img/${dataResults[idResult].img}.webp`} alt="" />
                </picture>
                :
                null
              }
            </div>

          </div>
          <div className={styles.text}>
            {dataResults.length > 0 && dataResults[idResult] !== undefined && dataResults[idResult].text !== undefined ?
              dataResults[idResult].text.map((element, index) => {
                return <p key={`text-${index}`}>{element}</p>
              })
              :
              null
            }
          </div>
          <div className={styles.feedback}>
            <div className={styles.feedback__text}>
              Sign here to be entered into the prize draw
              <Hint>
                <p>Send your Slack contact details to participate in the prize draw.</p>
                <p>On December 23, at 12:00 PM (GMT), 15 participants will be randomly selected to win Amazon/Ozon certificates or ETG merchandise: postcards, thermal mugs, and branded socks. We promise a good mood for everyone!</p>
              </Hint>
            </div>
            <div className={styles.feedback__icon}>
              <img src={`${path}/img/result-icon-2.svg`} alt="" />
            </div>
            <form className={styles.feedback__form} onSubmit={submitForm} ref={refForm}>
              <div className={styles.feedback__message}>Done</div>
              <input type="text" ref={refInputForm} placeholder='@slack' required />
              <button type="submit" className={styles.feedback__btn}>
                <Btn>Sign</Btn>
              </button>
            </form>
          </div>
          <div className={styles.reaction}>
            <div className={styles.reaction__icon}>
              <img src={`${path}/img/result-icon-3.svg`} alt="" /></div>
            <div className={styles.reaction__text}>What do you think of the project?</div>
            <div className={styles.reaction__items}>
              {
                [['A', 'So-so'], ['B', 'Okay'], ['C', 'Great'], ['D', 'Awesome']].map((element, index) => {
                  return <div className={styles.reaction__item} key={`r-item-${index}`} ref={element => refReactionItems.current[index] = element} onClick={() => submitReaction(element[0], index)}>
                    {element[1]}
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </div>

    </>
  );

}

export default Result;
